<template>
  <b-card-code title="Ajouter une voiture">
    <b-card-text>
      <span>Veuillez saisir les coordonnées de voiture </span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Matricule"
              label-for="matricule"
            >
              <validation-provider
                #default="{ errors }"
                name="matricule"
                rules="required"
              >
                <b-form-input
                  v-model="voiture.matricule"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Matricule"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Marque"
              label-for="marque"
            >
              <validation-provider
                #default="{ errors }"
                name="marque"
                rules="required"
              >
                <b-form-input
                  v-model="voiture.marque"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Marque "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Couleur"
              label-for="Couleur"
            >
              <validation-provider
                #default="{ errors }"
                name="Couleur"
                rules="required"
              >
                <b-form-input
                  v-model="voiture.couleur"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Couleur"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="addVoiture"
            >
              Ajouter
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
// import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
/* import vSelect from 'vue-select' */
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

  },
  data() {
    return {
      username: '',
      depot: '',
      depots: [{ nom: 'depot1' }],
      voiture: {
        marque: '',
        couleur: '',
        matricule: '',
      },
      required,
    }
  },

  methods: {
    async addVoiture() {
      await axios
        .post(
          '/api/cars/create/',
          {
            matricule: this.voiture.matricule,
            marque: this.voiture.marque,
            couleur: this.voiture.couleur,
          },
        )
        .then(() => {
          this.$router.push('/voitures/')
          setTimeout(() => {
            this.showToast('success', 'top-center', 'Voiture ajoutée avec succés')
          }, 1000)
        })
        .catch(err => {
          setTimeout(() => {
            this.showToast('danger', 'top-center', err.toString())
          }, 1000)
        })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
